<template>
  <div>
    <div v-if="loading" class="flex items-center justify-center h-full">
      <LoaderSpinner class="absolute top-[45vh]" />
    </div>

    <div v-else>
      <StorePage />

      <div
        v-if="!userToken"
        style="font-size: 24px"
        class="lg:flex lg:flex-wrap justify-center align-items-center py-[48px] px-[16px] md:px-[64px] w-[100%] bg-[#121212]">
        <a
          @click="goToLogin"
          class="flex justify-center gap-1 md:px-[24px] py-[8px] bg-[#F5AF0B] text-black text-center heading-xsmall rounded-lg w-[100%] m-auto max-w-[285px] md:max-w-[640px] lg:max-w-[1312px]">
          <img src="@/assets/icons/login.svg" alt="" />
          <span class="cursor-pointer">Login to view any products</span>
        </a>
      </div>

      <!-- <div
        v-if="userData && !userData.allow_store"
        class="lg:flex lg:flex-wrap justify-center align-items-center py-[48px] px-[16px] md:px-[64px] w-[100%] bg-[#121212]"
        style="font-size: 24px">
        <p
          class="flex justify-center gap-2 m-auto md:px-[24px] py-[8px] bg-[#F5AF0B] text-black lg:text-center heading-xsmall rounded-lg w-[100%] p-[16px] md:max-w-[640px] lg:max-w-[1312px]">
          <img src="@/assets/icons/lock.svg" alt="" />
          You can’t purchase any products at this time. Please check later when you get store access
        </p>
      </div> -->

      <div class="w-[100%] bg-[#121212]" v-if="products && userData && userData.allow_store">
        <div
          v-if="!userHasFullOBKGame"
          style="font-size: 24px"
          class="lg:flex lg:flex-wrap justify-center align-items-center pt-[48px] px-[16px] md:px-[64px] w-[100%]">
          <div
            class="flex justify-center gap-1 p-4 bg-[#322C3F] text-[#efedf2] text-center heading-xsmall rounded-lg w-[100%] m-auto max-w-[285px] md:max-w-[640px] lg:max-w-[1312px]">
            <img src="@/assets/icons/info.svg" alt="info-icon" class="mr-2" />
            <span class="heading-xsmall text-[#EFEDF2]">To unlock all of the Oh Baby Kart features, you just need to purchase anything from the store!</span>
          </div>
        </div>

        <div
          class="flex-col py-[48px] m-auto bg-[#121212] justify-start align-start mdmdlg:w-[610px] lg:w-[1012px] lg:pl-[80px] xl:pl-0 xl:w-[1312px] xs:w-[100%]">
          <!-- PRODUCTS WITH NO CATEGORIES -->
          <section v-if="all.length" class="flex-col h-auto normal w-[100%] m-auto">
            <div
              class="cards lg:flex flex-col align-start lg:gap-6 xs:flex-row-6 mb-[48px] h-[auto]"
              :class="!userHasFullOBKGame ? 'justify-center' : 'justify-between'">
              <div class="lg:flex lg:flex-wrap lg:items-3" :class="userHasFullOBKGame ? 'lg:gap-6' : ''">
                <!-- FULL GAME -->
                <div
                  class="cards lg:flex sflex-col justify-between align-start lg:gap-6 xs:flex-row-6 mb-[48px] h-[auto]">
                  <div class="lg:flex lg:flex-wrap lg:items-3 lg:gap-6">
                    <div v-for="(game, index) in fullGames" :key="index">
                      <NormalCards
                        class="normal-card"
                        :size="cardSizeNormal"
                        :product="game"
                        :icon="true"
                        :buyButton="!userOwnsGame(game.slug)"
                        :acquired="userOwnsGame(game.slug)"
                        :giftButton="true"
                        @mouseover="isHovered[game.id] = true"
                        @mouseleave="isHovered[game.id] = false">
                        <template v-slot:title>
                          {{ game.name }}
                        </template>
                        <template v-if="game.description" v-slot:message>
                          <div class="product-description-container">
                            <div v-html="sanitizeHTML(game.description)" class="description-content"></div>
                          </div>
                        </template>
                        <template v-else v-slot:message>
                          {{ game.short_description }}
                        </template>
                        <template v-slot:price>
                          <span v-if="game.sale_price">{{ game.sale_price }}</span>
                          <span v-else>{{ game.price }}</span>
                        </template>
                      </NormalCards>
                    </div>

                    <div v-for="product in all" :key="product.id">
                      <NormalCards
                        v-if="userHasFullOBKGame"
                        class="normal-card"
                        :class="(!userHasFullOBKGame || this.userData.disabled_products.includes(product.id)) ? 'disabled-card' : ''"
                        :locked="(!userHasFullOBKGame || this.userData.disabled_products.includes(product.id))"
                        :size="cardSizeNormal"
                        :product="product"
                        :icon="isMobile || isHovered[product.id]"
                        @mouseover="(userHasFullOBKGame && !this.userData.disabled_products.includes(product.id)) ? (isHovered[product.id] = true) : ''"
                        @mouseleave="(userHasFullOBKGame && !this.userData.disabled_products.includes(product.id)) ? (isHovered[product.id] = false) : ''">
                        <template v-slot:title>
                          {{ product.name }}
                        </template>
                        <template v-if="product.description" v-slot:message>
                          <div class="product-description-container">
                            <div v-html="sanitizeHTML(product.description)" class="description-content"></div>
                          </div>
                        </template>
                        <template v-else v-slot:message>
                          {{ product.short_description }}
                        </template>
                        <template v-slot:price>
                          <span v-if="product.sale_price">{{ product.sale_price }}</span>
                          <span v-else>{{ product.price }}</span>
                        </template>
                      </NormalCards>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div v-for="(item, index) in categories" :key="index">
            <div v-if="item.products">
              <div v-if="item.slug !== 'other'" class="flex-col h-auto normal">
                <h1 class="listing-section-headings capitalize text-center lg:text-left heading-large mb-[24px]">
                  {{ item.name }}
                </h1>
                <div
                  class="cards lg:flex sflex-col justify-between align-start lg:gap-6 xs:flex-row-6 mb-[48px] h-[auto]">
                  <div class="lg:flex lg:flex-wrap lg:items-3 lg:gap-6">
                    <div v-for="product in item.products" :key="product.id">
                      <NormalCards
                        class="normal-card"
                        :class="!userHasFullOBKGame ? 'disabled-card' : ''"
                        :size="cardSizeNormal"
                        :product="product"
                        :icon="isMobile || isHovered[product.id]"
                        @mouseover="userHasFullOBKGame ? (isHovered[product.id] = true) : ''"
                        @mouseleave="userHasFullOBKGame ? (isHovered[product.id] = false) : ''">
                        <template v-slot:title>
                          {{ product.name }}
                        </template>
                        <template v-slot:message>
                          {{ product.short_description }}
                        </template>
                        <template v-slot:price>
                          <span v-if="product.sale_price">{{ product.sale_price }}</span>
                          <span v-else>{{ product.price }}</span>
                        </template>
                      </NormalCards>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ACCORDIAN -->
      <div class="flex items-center justify-center">
        <FAQ />
      </div>
     

      <div :class="{ 'w-[100%] lg:mt-[100px] xl:mt-0 xxxl:mt-[172px] bg-[#0e0d12]': !userToken }">
        <StoreFooter />
      </div>
    </div>
  </div>

  <AgeVerificationModal v-if="!over18" />
</template>

<script>
import envConfig from "../config";
import axios from "axios";
import NormalCards from "./cards/NormalCards.vue";
// import SpecialCards from "./cards/SpecialCards.vue";
import AgeVerificationModal from "./AgeVerificationModal.vue";
import StoreFooter from "./StoreFooter.vue";
import FAQ from "./FAQsNew.vue";
import StorePage from "../components/StorePage.vue";
import LoaderSpinner from "./UI/LoaderSpinner.vue";

export default {
  components: {
    AgeVerificationModal,
    FAQ,
    StoreFooter,
    // SpecialCards,
    NormalCards,
    StorePage,
    LoaderSpinner,
  },
  data() {
    return {
      loading: true,

      contrast: false,
      products: null,
      all: null,
      screenWidth: window.innerWidth,
      isHovered: {},
      normalHover: {},
      userData: null,
      userToken: null,
      categories: [],
      fullGames: null,
    };
  },
  computed: {
    over18() {
      // Check if the user has already agreed
      return localStorage.getItem("over18");
    },
    cardSizeSpecial() {
      return this.screenWidth < 760 ? "small-card" : "large-card";
    },
    cardSizeNormal() {
      return this.screenWidth < 760 ? "normal-small-card" : "normal-large-card";
    },
    isMobile() {
      return this.screenWidth < 1024;
    },
    userHasFullOBKGame() {
      if (this.userData && this.fullGames) {
        return this.userOwnsGame("kart1") || this.userOwnsGame("thefullgame");
      }
      return false;
    },
  },

  mounted() {
    this.fetchCategories();
    this.fetchProducts();
    window.addEventListener("resize", this.updateScreenWidth);
    this.userToken = window.getCookie("obb_ck");
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      const url = envConfig.baseUrl + "/api/user";

      if (this.userToken) {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.userToken}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            const data = response.data;
            this.userData = data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get(`${envConfig.baseUrl}/api/product-categories`);
        this.categories = response.data.data;
        this.categories.push({
          id: this.categories.length + 1,
          name: "Other",
          slug: "other",
          sort_order: 0,
          products: null,
        });

        this.categories = this.categories.filter(category => category.slug !== 'hide-from-front-store-page');

        // Categories sorted by sort order
        this.categories.sort((a, b) => a.sort_order - b.sort_order);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get(`${envConfig.baseUrl}/api/products`);
        const data = response.data.data;
        this.products = data;

        let filteredFullProduct = data.filter((product) => product.slug === product.game_slug);

        this.fullGames = filteredFullProduct.length > 0 ? filteredFullProduct : null;

        const filteredAll = data.filter((product) =>
            !filteredFullProduct.includes(product) && product.category !== 'hide-from-front-store-page'
        );
        if (this.categories.length > 1) {
          this.organizeProducts();
        } else {
          this.loading = true;
          this.fetchProducts();
        }

        this.all = filteredAll;
        return this.products;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },

    organizeProducts() {
      this.products.forEach((item) => {
        const category = item.category;
        const index = this.categories.findIndex((item) => item.slug === category);
        const other = this.categories.findIndex((item) => item.slug === "other");

        if (index !== -1 && this.categories) {
          if (!this.categories[index]["products"]) {
            this.categories[index]["products"] = [];
          }
          this.categories[index]["products"].push(item);
        } else {
          if (!this.categories[other]["products"]) {
            this.categories[other]["products"] = [];
          }
          this.categories[other]["products"].push(item);
        }
      });
      this.loading = false;
    },

    sanitizeHTML(html) {
      if (html) {
        // Wrap each <li> with <span> and • separator
        let sanitizedHTML = html.replace(/<li>(.*?)<\/li>/g, "<span>$1</span>");

        // Remove unnecessary separators and empty spans
        sanitizedHTML = sanitizedHTML.replace(/(<span>\s*•\s*)+<\/span>/g, ""); // Remove separators and empty spans at once
        sanitizedHTML = sanitizedHTML.replace(/^<span>\s*•\s*/, ""); // Remove leading separator
        sanitizedHTML = sanitizedHTML.replace(/<span>\s*•\s*<\/span>/g, ""); // Remove empty spans

        return sanitizedHTML;
      } else {
        return ""; // Return an empty string if html is null or undefined
      }
    },

    goToLogin() {
      window.location.href = `${envConfig.websiteUrl}/login?from=store`;
    },

    userOwnsGame(gameSlug) {
      return !!this.userData.purchased_products.find((e) => e.product_slug === gameSlug);
    },
  },
};
</script>

<style>
body {
  background-color: #0e0d12;
}

.disabled-card .card-img,
.disabled-card section {
  opacity: 0.3;
}

.description-content ul {
  width: fit-content;
}
.description-content span {
  margin-right: 3px;
  width: fit-content;
}
.description-content span:not(:last-child)::after {
  content: "•";
  margin-left: 3px;
}

@media screen and (max-width: 767px) {
  .listing-section-headings {
    font-size: 24px !important;
    font-weight: 500;
    line-height: 32px;
    max-width: 288px;
    text-align: left !important;
    margin: auto !important;
    padding: 0 0;
  }
}


</style>
